import Slider from './Slider';

export default function () {
  const defaultOptions = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 30,
    preloadImages: true,
    updateOnImagesReady: true,
    freeMode: true,
    freeModeMomentumBounceRatio: 0.1,
    freeModeSticky: true,
    watchOverflow: true,
    loop: true,
    // navigation: {
    //   nextEl: '.js-slider-next',
    //   prevEl: '.js-slider-previous',
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  // Configuration overrides

  const configurations = new Map();

  configurations.set('slider--horizontal', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: false,
    loop: false,
    watchOverflow: true,
    freeMode: true,
    freeModeMomentumBounceRatio: 0.1,
    freeModeSticky: true,
    updateOnWindowResize: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  // Init sliders

  const sliders = [...document.querySelectorAll('.slider')];
  sliders.forEach((sliderEl) => {
    const sliderClasses = sliderEl.classList.toString();

    let slider;
    configurations.forEach((options, overrideClass) => {
      if (sliderClasses.indexOf(overrideClass) >= 0) {
        slider = new Slider(sliderEl, options);
        slider.init();
      }
    });
    if (!slider) {
      slider = new Slider(sliderEl, defaultOptions);
      slider.init();
    }
  });
}
