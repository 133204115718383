import AOS from 'aos';

export default function () {
  AOS.init({
    once: false,
  });

  window.addEventListener('load', () => {
    AOS.refresh();
  });
}
