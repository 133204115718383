import bounty from 'bounty';

export default function () {
  const odometer = document.querySelector('.js-odometer');
  const count = document.querySelector('.js-odometer-count');

  if (odometer) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          count.classList.add('js-odometer-count-animate');

          bounty({
            el: '.js-odometer-count-animate',
            value: '197426',
            initialValue: '000000',
            letterSpacing: 18,
            animationDelay: 100,
            letterAnimationDelay: 100,
            duration: 9000,
          });

          observer.unobserve(odometer);
        }
      });
    });

    observer.observe(odometer);
  }
}
