import Swiper from 'swiper';

export default class Slider {
  constructor(sliderEl, options) {
    this.element = sliderEl;
    this.options = options;
  }

  init() {
    this.swiper = new Swiper(this.element.querySelector('.swiper-container'), this.options);
  }
}
