import $ from 'jquery';

export default function () {
  const $fields = $('.infographic-battery');
  const $container = $('.infographic-batteries');
  const width = $container.width();
  const height = $container.height();
  const radius = width * 0.31496062992;

  let angle = 0;
  const step = (2 * Math.PI) / $fields.length;

  $fields.each((index, element) => {
    const $element = $(element);
    const x = Math.round(width / 2 + radius * Math.cos(angle) - $element.width() / 2);
    const y = Math.round(height / 2 + radius * Math.sin(angle) - $element.height() / 2);

    const containerWidth = $container.width();
    const containerHeight = $container.height();

    const left = x / containerWidth * 100;
    const top = y / containerHeight * 100;

    $element.css({
      left: `${left}%`,
      top: `${top}%`,
    });

    angle += step;
  });
}
