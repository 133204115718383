import $ from 'jquery';
// import lightGallery from 'lightgallery';

export default function () {
  $('.js-massabalans').lightGallery({
    // mode: 'lg-slide',
    fullscreen: true,
    selector: 'a',
  });
}
