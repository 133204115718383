/* eslint-disable no-unused-vars */
import { gsap, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

export default () => {
  const parallaxItems = document.querySelectorAll('.js-parallax-main');

  if (parallaxItems.length) {
    [...parallaxItems].forEach((el) => {
      const targets = el.querySelectorAll('.js-parallax');
      [...targets].forEach((item) => {
        const yPercent = item.dataset.y ? parseInt(item.dataset.y, 10) : 0;
        const xPercent = item.dataset.x ? parseInt(item.dataset.x, 10) : 0;
        const rotation = item.dataset.rotate ? parseInt(item.dataset.rotate, 10) : 0;

        gsap.to(item, {
          yPercent,
          xPercent,
          rotation,
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });
      });
    });
  }
};
