/* eslint-disable no-unused-vars */
import $ from 'jquery';
import ProgressBar from 'progressbar.js';

export default function () {
  if ($('#prive').length) {
    const prive = new ProgressBar.Line('#prive', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#AFCB2E',
      trailWidth: 1,
      svgStyle: {
        width: '100%',
        height: '100%',
      },
    });
    prive.animate(1.0);
  }
  if ($('#merken').length) {
    const merken = new ProgressBar.Line('#merken', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#A38C32',
      trailWidth: 1,
      svgStyle: {
        width: '55%',
        height: '100%',
      },
    });
    merken.animate(1.0);
  }
  if ($('#multi').length) {
    const multi = new ProgressBar.Line('#multi', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#F2E61A',
      trailWidth: 1,
      svgStyle: {
        width: '30%',
        height: '100%',
      },
    });
    multi.animate(1.0);
  }
  if ($('#sloper').length) {
    const sloper = new ProgressBar.Line('#sloper', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#5687B6',
      trailWidth: 1,
      svgStyle: {
        width: '28%',
        height: '100%',
      },
    });
    sloper.animate(1.0);
  }
  if ($('#berging').length) {
    const berging = new ProgressBar.Line('#berging', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#6A6483',
      trailWidth: 1,
      svgStyle: {
        width: '21%',
        height: '100%',
      },
    });
    berging.animate(1.0);
  }
  if ($('#publiek').length) {
    const publiek = new ProgressBar.Line('#publiek', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#D51317',
      trailWidth: 1,
      svgStyle: {
        width: '7%',
        height: '100%',
      },
    });
    publiek.animate(1.0);
  }
  if ($('#verzekering').length) {
    const verzekering = new ProgressBar.Line('#verzekering', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#DA949A',
      trailWidth: 1,
      svgStyle: {
        width: '1.8%',
        height: '100%',
      },
    });
    verzekering.animate(1.0);
  }
  if ($('#constructeur').length) {
    const constructeur = new ProgressBar.Line('#constructeur', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#E4964D',
      trailWidth: 1,
      svgStyle: {
        width: '1.6%',
        height: '100%',
      },
    });
    constructeur.animate(1.0);
  }
  if ($('#leasing').length) {
    const leasing = new ProgressBar.Line('#leasing', {
      easing: 'easeInOut',
      strokeWidth: 4,
      duration: 1400,
      color: '#7C7C7B',
      trailWidth: 1,
      svgStyle: {
        width: '1.5%',
        height: '100%',
      },
    });
    leasing.animate(1.0);
  }
}
