export default function () {
  const audio = document.getElementById('podcast-audio');
  const control = document.querySelector('.js-play-btn');
  const skip = document.querySelector('.js-skip-btn');
  const player = document.querySelector('.js-podcast-player');

  if (player) {
    const playPause = () => {
      if (audio.paused) {
        audio.play();
        player.classList.add('playing');
      } else {
        audio.pause();
        player.classList.remove('playing');
      }
    };

    const reset = () => {
      if (!audio.paused) {
        audio.currentTime = 0;
      }
    };

    control.addEventListener('click', playPause);
    skip.addEventListener('click', reset);
    audio.addEventListener('ended', () => {
      player.classList.remove('playing');
    });
  }
}
