/* eslint-disable no-unused-vars */
import $ from 'jquery';
import modaal from 'modaal';
import { matchMedium } from '../../constants/defaults';

export default function () {
  $('.main-nav__trigger').modaal({
    fullscreen: true,
    background_scroll: false,
  });
}
