import anime from 'animejs/lib/anime.es';
import carInfographic from './infographic-cars';
import batteryInfographic from './infographic-batteries';
import aos from './aos';
import gsap from './gsap';
import odometer from './odometer';

export default function () {
  carInfographic();
  batteryInfographic();
  aos();
  gsap();
  odometer();

  const numbers = document.querySelectorAll('.js-animate-number');

  if (numbers) {
    const animateNumbers = (el) => {
      const animatedNumber = el.querySelector('.js-number');
      const value = animatedNumber.dataset.val;

      anime({
        targets: animatedNumber,
        innerHTML: [0, value],
        easing: 'easeOutCirc',
        duration: 2000,
        round: 1,
      });
    };

    const onIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateNumbers(entry.target);
          observer.unobserve(entry.target);
        }
      });
    };

    const options = {
      root: null,
      rootMargin: '50px 0px',
      threshold: 0.7,
    };

    const observedNumbers = document.querySelectorAll('.js-animate-number');
    const observer = new IntersectionObserver(onIntersection, options);

    observedNumbers.forEach((number) => {
      observer.observe(number);
    });
  }
}
