/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
import $ from 'jquery';
// eslint-disable-next-line import/order
import resizeSensor from './resizeSensor';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar';
import Gumshoe from 'gumshoejs/dist/gumshoe';
import {
  matchMedium,
  matchLarger,
  matchMediumHeight,
  matchLargeHeight,
  matchLarge,
} from '../../constants/defaults';

export default function () {
  if (document.querySelector('.js-sticky-sidebar')) {
    const gumshoe = new Gumshoe('.js-scrollspy-navigation a', {
      offset: 400,
      events: true,
    });

    let sidebar;

    const initStickySidebar = (top) =>
      new StickySidebar('.js-sticky-sidebar', {
        containerSelector: '.js-sticky-sidebar-container',
        topSpacing: top,
        bottomSpacing: 0,
        resizeSensor: true,
      });
    const initStickySidebarLarge = () =>
      new StickySidebar('.js-sticky-sidebar', {
        containerSelector: '.js-sticky-sidebar-container',
        topSpacing: 200,
        bottomSpacing: 20,
      });

    // Listen for activate events
    document.addEventListener(
      'gumshoeActivate',
      (event) => {
        const { link } = event.detail;
        const imgName = $(link).attr('data-url');
        $('.js-icon').find('source').attr('src', `${imgName}`);
        $('.js-icon')[0].load();
        $('.js-icon').get(0).play();
      },
      false,
    );

    window.addEventListener('load', (event) => {
      if (matchMedium && matchMedium.matches && !matchLarger.matches) {
        sidebar = initStickySidebar(60);
      }

      if (matchLarger && matchLarger.matches) {
        sidebar = initStickySidebar(110);
      }
      gumshoe.setup();
    });

    if ($(window).height() < 600) {
      $('.show-medium-height').show();
      sidebar = initStickySidebar(100);
      $('.inner-wrapper-sticky .line-inner').hide();
    } else {
      $('.show-medium-height').hide();
      $('.inner-wrapper-sticky .line-inner').show();
    }

    $(window).resize(() => {
      if (matchMedium && matchMedium.matches && !matchLarger.matches) {
        sidebar = initStickySidebar(60);
        gumshoe.setup();
      }

      if (matchLarger && matchLarger.matches && matchLargeHeight.matches) {
        sidebar = initStickySidebar(200);
        gumshoe.setup();
      }
      if ($(window).height() < 600) {
        $('.show-medium-height').show();
        sidebar = initStickySidebar(100);
        $('.inner-wrapper-sticky .line-inner').hide();
      } else {
        $('.show-medium-height').hide();
        $('.inner-wrapper-sticky .line-inner').show();
      }
    });
  }
}
